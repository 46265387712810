<template>
    <div class="topic-info">
      <div style="width: 290px; position: fixed;">
        <div style="width: 100%; min-height: 426px; background: #fff;margin-bottom: 10px;overflow: hidden;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
          <HotTopic :size="20"></HotTopic>
        </div>
      </div>
        <div class="topic_center_content" style="margin-left: 300px">
            <div style="background:#FFF;padding: 5px 5px;margin-bottom: 10px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
                <div style="height:100px; overflow:auto;">
                    <div class="head_ico" style="float:left;width:80px;height:80px;margin:10px;border-radius:10px;overflow:hidden;">
                        <img :src="topic.bgimg+'-80x80'" style="width:100%;height:100%;">
                    </div>
                    <div style="float:left;margin:10px 0;">
                        <div style="height:40px;line-height:40px;font-size:25px;font-weight:bold;">
                            {{topic.name}}
                            <span class="el-dropdown-link" @click="a('/topic/edit/'+topic.name)" v-if="topic.createTopicUser.uuid == user.uuid" style="line-height: 20px;" >
                                <el-link type="primary">编辑</el-link>
                            </span>
                        </div>
                        <div v-show="topic.topicTypeName1 != '' && topic.topicTypeName2 != ''" style="height:20px;line-height:20px;font-size:12px;">话题分类：<span style="color: #FF9800;cursor: pointer;">{{topic.topicTypeName1}} -> {{topic.topicTypeName2}}</span></div>
                        <div v-show="topic.topicTypeName1 == '' && topic.topicTypeName2 == ''" style="height:20px;line-height:20px;font-size:12px;">话题分类：<span style="color: #FF9800;cursor: pointer;">暂无分类</span></div>
                        <div style="height:20px;line-height:20px;color:#666666;font-size:12px;">{{topic.clickCount}}浏览 · {{topic.discussCount}}讨论</div>
                    </div>
                </div>

                <div style="margin:10px;position: relative; overflow:hidden;" >
                    <div v-show="topic.detail != ''">{{topic.detail}}</div>
                    <div v-show="topic.detail == ''">暂无简介</div>
                </div>
            </div>

            <div style="display:flex;background: #ffffff; margin-bottom: 10px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
                <div style="width: 100%; box-shadow: none !important;margin: 10px;">
                    <Release v-if="this.topic.id > 0" shadow="0" :paramsTopicId="''+this.topic.id+''" :placeholder="'#' + this.topics +'#'" :topicOpen="false" class="no_shadow"></Release>
                </div>
            </div>
            <div style="width: 100%; overflow: hidden; margin-bottom: 10px; background-color: white; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
              <div class="tab border_bottom up" v-for="(item, index) in personalMenu" :key="index" @click="indexTags = item.key">
                <label>
                  <div class="head_white hand">
                    <div class="head_white_word" :style="indexTags === item.key ? 'color: #2970FF' : ''" @click="clickNavigation(item.key)">{{item.value}}</div>
                  </div>
                </label>
              </div>
                <div class="tab-search">
                    <el-input placeholder="请输入搜索内容" v-model="searchContent">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
            </div>

            <div class="contents">
                <template>
                    <div  style="width: 100%; overflow:hidden; ">
                        <div v-for="(item,index) in content_list" v-bind:key="index" >
                            <ContentAll :data="item" type="all"></ContentAll>
                        </div>
                    </div>
                </template>

              <div v-if="content_list.length === 0" style="width: 100%; overflow:hidden;">
                <NullBox data="发布内容"></NullBox>
              </div>
<!--                <div style="text-align:center;padding:20px 0;background:#FFF; margin:10px 0;color:#666;font-size:15px;" v-if="content_list.length % 20 == 0 && content_list.length != 0">加载中 <i class="el-icon-loading"></i></div>-->
            </div>
        </div>

        <div class="right_content">
            <div class="div" style="background: #ffffff; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
                <!--                 <div class="angle"></div> -->
                <div>
                  <div class="industry_title_text">话题创建者</div>
                </div>
                <div class="text" style="padding: 0 10px 10px 10px;">
                    <CircleUser v-if="topic.id > 0" :userData="topic.createTopicUser"></CircleUser>
                </div>
            </div>

            <div class="div" style="margin-top:10px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);">
                <!--                 <div class="angle"></div> -->
              <div>
                <div class="industry_title_text">话题贡献排行</div>
              </div>
                <div class="text" style="overflow: hidden; padding-bottom: 10px">
                    <div  v-for="(users,index) in topic.TopicContributionList" :key="index" @click="openInfo(users.uuid, users.userType)">
                      <CircleUser style="margin-bottom: 10px" v-if="topic.id > 0" :userData="users"></CircleUser>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Release from "../circle/components/Release";
    import ContentAll from "../circle/components/ContentAll";
    import Dynamic from "../circle/components/Dynamic";
    import ImagesContent from "../circle/components/ImagesContent";
    import VideoContent from "../circle/components/VideoContent";
    import Article from "../circle/components/Article";
    import Enclosure from "../circle/components/Enclosure";
    import CircleUser from "@/pages/circle/components/circleUser.vue";
    import HotTopic from "@/pages/circle/components/hot/HotTopic.vue";
    import NullBox from "@/pages/circle/components/NullBox.vue";

    export default {
        components: {
          NullBox,
          HotTopic,
          CircleUser,
            Release,
            ContentAll,
            Dynamic,
            ImagesContent,
            VideoContent,
            Article,
            Enclosure
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                topic_list:[],
                topics:"",
                topic:{
                    name:"",
                    detail:"",
                    user:{
                        head_ico:"",
                        nick_name:"",
                    },
                    bg_img:"",
                    createTopicUser: {}
                },
                //文章类型
                original_list:[],
                content_list:[],
                images_list : [],
                videos_list : [],
                enclosure_list: [],
                article_list : [],
                personalMenu: [
                  {
                    key: 'all',
                    value: '全部'
                  },
                  {
                    key: 'dynamic',
                    value: '动态'
                  },
                  {
                    key: 'images',
                    value: '图片'
                  },
                  {
                    key: 'videos',
                    value: '视频'
                  },
                  {
                    key: 'enclosure',
                    value: '附件'
                  },
                  {
                    key: 'article',
                    value: '文章'
                  },
                ],

                // 搜索词
                searchContent: '',
                page:1,
                pageSize:10,
                // 标签
                indexTags: 'all',
                more: 1,
                loading: false,
                like2:"",
                type_id:0,
            }
        },
        watch: {
            //监听相同路由下参数变化的时候，从而实现异步刷新
            '$route'() {
                this.topics = this.$route.params.topic;
                if (this.$route.params.topic) {
                    this.getTopicInfo();

                }
                this.page = 1;
                this.original_list = [];
                this.content_list = [];
                this.backTop();
            },
        },
        mounted:function(){
            var that = this;
            //接收事件
            this.$EventBus.$on("changeData", ({ changeData }) => {
                this.$nextTick(() => {
                    console.log(changeData);
                    this.page = 1;
                    this.content_list = [];
                    this.getInspirationList();
                });
            });
            this.topics = this.$route.params.topic;
            this.getTopicInfo();
            this.backTop();

            window.onscroll = function(){
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
                //滚动条到底部的条件

              if (scrollHeight - scrollTop - windowHeight <= 0) {
                if (that.more == 1 && that.loading === false) {
                  var type = 0;
                  switch (that.indexTags) {
                    case 'all':
                      type = 0;
                      break;
                    case 'dynamic':
                      type = 1;
                      break;
                    case 'article':
                      type = 2;
                      break;
                    case 'images':
                      type = 3;
                      break;
                    case 'videos':
                      type = 4;
                      break;
                    case 'enclosure':
                      type = 5;
                      break;
                  }
                  that.load();
                  that.getInspirationList(type);
                } else {
                  console.log("已加载完");
                }
              }
            }
        },
        methods:{
            backTop () {
                //document.documentElement.scrollTop = document.body.scrollTop = 0;
            },
          load() {
            this.loading = true;
          },
          // 点击打开用户信息
          openInfo(uuid, userType) {
            if (!this.utils.toAuth(1)) {
              return false
            }
            if (userType === 1) {
              this.utils.a('/circle/mySpace/' + uuid)
            }
            if (userType === 2 || userType === 3) {
              this.utils.a('/circle/organSpace/' + uuid)
            }
          },
            clickNavigation(str){
                var that = this;
                var type = 0;
                that.indexTags = str;
                that.content_list = [];
                switch (str)
                {
                    case 'all':type = 0;
                        break;
                    case 'dynamic':type = 1;
                        break;
                    case 'article':type = 2;
                        break;
                    case 'images':type = 3;
                        break;
                    case 'videos':type = 4;
                        break;
                    case 'enclosure':type = 5;
                        break;
                }
                that.page = 1;
                that = that.getInspirationList(type);
            },
            getInspirationList:function(type = 0){
                var that = this;
                that.loading = true;
                var params = {};
                params.topicId = that.topic.id;
                params.type = type;
                params.page = that.page;
                params.pageSize = that.pageSize;
                this.newApi.getInspirationList(params).then((res) => {
                  for (const item of res.data) {
                    that.original_list.push(item);
                    that.content_list.push(item);
                    that.more = 1;
                  }
                  if (res.data.length < 10) {
                    that.more = 0;
                    that.utils.sus('全部加载完成！')
                  } else {
                    that.page ++;
                  }
                  that.loading = false
                })
            },


            getTopicInfo:function(){
                var that = this;
                that.newApi.getTopicInfo({name:that.topics}).then((ret) => {
                    that.topic = ret.data;
                    that.getInspirationList();
                })
            },
        }
    }


</script>
<style>
    .topic-info{
        background: #F0F0F0;
        overflow: hidden;
        width: 1500px;
        min-height: 100vh;
        margin: 0 auto;
        margin-top: 108px;
    }

    .topic-info .industry_title_text{
      line-height: 40px;
      margin-left: 10px;
      color:#031F88;
      font-size: 18px;
      font-weight: bold;
    }

    .topic_center_content{
        float:left;width: 900px;
    }
    .right_content{
        width:290px; float:left; margin-left: 10px;
    }
    .home_page_select .el-input__inner{
        border:none;
    }
    .home_page_select .el-tabs__header{
        margin:0;
    }

    .el-carousel__container{
        height:270px;
    }
    .el-carousel__item img{
        width:100%;height:100%;
    }
    .home_page_select .el-tabs__item {
        padding: 0 16px;
    }
</style>
<style scoped>
    .no_shadow{
        box-shadow: none !important;
    }
    .vjs_video_3-dimensions{
        width:500px !important;
        height:300px;
    }

    .video-js .vjs-big-play-button {
        top: 117px;
        left: 195px;
    }

    .div{
        background:#FFF;position:relative;font-size: 14px;
    }

    .div .text{
        color:#666; margin-top:10px;
        white-space: pre-wrap;
        overflow: hidden;
        padding:0px 15px;
        /*max-height:88px;overflow:hidden;line-height:22px; position:relative;*/
    }
    .div .text2{
        color:#666; /*margin-top:10px;*/
        white-space: pre-wrap;
        overflow: hidden;
        /*max-height:88px;overflow:hidden;line-height:22px; position:relative;*/
    }

    .tab{
      text-align: center;
      margin: auto 0;
      float: left;
      cursor:pointer;
    }

    .tab-search {
      width: 30%;
      float: right;
      margin: 7px 2%;
      cursor:pointer;
    }

    .up{
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 0.1s;
    }
    .head_white{
      height: 26px;
      padding: 14px 0;
    }
    .head_white_word{
      width: 70px;
      height: 26px;
      text-align: center;
      line-height:26px;
      color: #999999;
      font-size: 17px;
      font-weight: 600;
      margin-left: 20px;
      float: left;
    }


</style>
